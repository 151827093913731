
import { Component, Vue } from "vue-property-decorator";
import { $auth, AuthRole } from "@/pages/auth/services/auth.service";

@Component({
  name: "login-page"
})
export default class LoginPage extends Vue {
  codeImageUrl = '';
  roles = Object.values($auth.roles);
  currentRole: AuthRole = $auth.buildRole();
  form = $auth.form;
  loading = false;
  isMounted = false;
  mobileForm = $auth.mobileForm;
  activeName = 'account';
  smsSuffixText = '获取验证码';
  smsTimeout = 0;
  smsCount = 0;
  get pwdCover() {
    return new Array(this.form.pwd.length).fill('●').join('');
  }

  mounted() {
    this.refreshCode();
    setTimeout(() => {
      this.isMounted = true;
    }, 500);
  }

  async clickSmsSuffixText() {
    let is: any
    await (this.$refs.mobileFormRef as any).validateField('mobile', (valid: any) => void (is = valid))

    if (this.smsTimeout || is) return
    const { mobile, captcha } = this.mobileForm
    if (!/^1[3456789]\d{9}$/.test(mobile)) return
    await $auth.smscodeDropShipping(mobile, captcha || undefined)
    let timer: any = null
    this.smsTimeout = 60
    this.smsSuffixText = '60秒'
    timer = setInterval(() => {
      this.smsTimeout--;
      if (this.smsTimeout <= 0) {
        this.smsSuffixText = '获取验证码';
        this.smsTimeout = 0;
        clearInterval(timer);
      } else {
        this.smsSuffixText = this.smsTimeout + '秒';
      }
    }, 1000);
  }

  refreshCode() {
    this.codeImageUrl = this.currentRole.getCodeImageUrl('LOGIN');
    this.$forceUpdate();
  }

  switchRole(role) {
    this.currentRole = $auth.switchRole(role);
    $auth.clearForm();
    this.refreshCode();
  }

  removeAccount(ref, value: string) {
    this.currentRole.removeAccount(value);
    ref.debouncedGetData(ref.value);
  }

  handleSelectAccount(account) {
    $auth.selectAccount(account.value, account.pwd);
    this.$forceUpdate();
  }

  // login(formRef) {
  //   formRef.validate(valid => {
  //     if (valid) {
  //       this.loading = true;
  //       $auth.login().then(() => {
  //         this.loading = false;
  //       }, () => {
  //         this.loading = false;
  //         this.refreshCode();
  //       });
  //     }
  //   });
  // }
  async login(formRef) {
    try {
      const valid = await formRef.validate();
      console.log(valid);

      if (!valid) return;
      this.loading = true;
      try {
        if (this.activeName === 'account') await $auth.login();
        else if (this.activeName === 'mobile') {
          try {
            await $auth.loginDropShipping();
          } catch (error) {
            this.refreshCode();
            this.smsCount++
          }
        }
      } catch (error) {
        this.refreshCode();
      } finally {
        this.loading = false;
      }
    } catch (error) {

    }
  }
}
